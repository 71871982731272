<template>
  <v-carousel
    delimiter-icon="mdi-circle"
    hide-delimiter-background
    :cycle="true"
    class="mb-3"
  >
    <v-carousel-item
      v-for="(item, i) in items"
      :src="item"
      :key="i"
    ></v-carousel-item>
  </v-carousel>
</template>
<script>
import apiHome from "@/api/home";
import { mapActions } from "vuex";
import { global } from "@/api/global";

export default {
  data() {
    return {
      items: [],
    };
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    getCarouselItems() {
      apiHome
        .getCarouselItems()
        .then((response) => {
          this.items = response.data;
          this.items.forEach((item, index, theArray) => {
            theArray[index] = global.baseUrl + item;
          });
        })
        .catch((error) => {
          this.setError(error);
        });
    },
  },
  mounted() {
    this.getCarouselItems();
  },
};
</script>